/* global sessionStorage, document, window */

import { defineStore } from 'pinia';
import { ref, computed, toValue } from 'vue';
import { jwtDecode } from 'jwt-decode';

import {
    createUserWithEmailAndPassword,
    sendSignInLinkToEmail,
    verifyPasswordResetCode,
    confirmPasswordReset,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signInWithCustomToken,
    isSignInWithEmailLink,
    signInWithEmailLink,
    signInWithPopup,
    signOut,
    getIdToken,
    sendEmailVerification,
    deleteUser,
    checkActionCode,
    applyActionCode,
    updatePassword,
    multiFactor,
    PhoneMultiFactorGenerator,
    getMultiFactorResolver,
    TotpMultiFactorGenerator,
    SAMLAuthProvider,
} from 'firebase/auth';

import { auth } from '@/scripts/firebaseConfig';
import api from '@/scripts/api';
import logger from '@/scripts/logger';

export const useUserStore = defineStore('user', () => {
    const me = ref(null);

    const processing = ref(false);

    const currentAuthData = ref(null);
    const multiFactorUser = ref(null);

    const accessToken = ref(sessionStorage.getItem('authToken'));

    if (sessionStorage.getItem('gsAuth')) {
        try {
            currentAuthData.value = JSON.parse(sessionStorage.getItem('gsAuth'));
        } catch (err) {
            logger.error(err);
            sessionStorage.removeItem('gsAuth');
        }
    }

    const decodedToken = computed(() => {
        let _accessToken = toValue(accessToken);
        if (_accessToken) return jwtDecode(_accessToken);
        return null;
    });

    const isViewMode = computed(() => {
        return toValue(decodedToken)?.scope === 'view';
    });

    const isReadOnly = computed(() => {
        return toValue(decodedToken)?.scope === 'read';
    });

    let tempAuthData;

    const mfaDevices = computed(() => {
        const _multiFactorUser = toValue(multiFactorUser);
        logger.debug('current MFA devices requested => ', _multiFactorUser);
        return (_multiFactorUser?.enrolledFactors || []).sort((a) => (a.factorId === TotpMultiFactorGenerator.FACTOR_ID ? -1 : 1));
    });

    const userId = computed(() => {
        return me.value?.id;
    });

    const loggedIn = computed(() => {
        return me.value?.id !== undefined || toValue(isViewMode);
    });

    async function updateCurrentUser(refresh) {
        if (me.value && !refresh) {
            logger.debug('current user (me) skipped (!refresh), current value', me.value);
            return;
        }

        try {
            // Get the current user from the server
            let response = await api.getCurrentUser();

            logger.debug('current user (me) set', response.data);

            me.value = response.data;
        } catch (err) {
            // If the user does not exist, log them out and return to the home page
            if (err.response.status === 404) {
                err.code = 'auth/user-not-found';
                logger.debug('user does not exist on the system');
                logout().catch();
                throw err;
            }
            logger.debug('error getting current user', err);
            throw err;
        }
    }

    // When our auth status changes, call whoever is listening
    auth.onIdTokenChanged((authData) => {
        if (processing.value) return;

        let _decodedToken = toValue(decodedToken);

        if (_decodedToken?.iss === 'goskript') return;

        currentAuthData.value = authData;

        try {
            multiFactorUser.value = multiFactor(toValue(currentAuthData));
        } catch {
            multiFactorUser.value = null;
        }

        if (!authData) {
            logger.debug('auth.onIdTokenChanged - Token cleared');

            setToken(null);
        } else {
            logger.debug('auth.onIdTokenChanged - Token set', authData);

            setToken(authData['accessToken'], authData['stsTokenManager'].expirationTime);

            updateCurrentUser().catch();
        }
    });

    function setToken(newToken, expirationTime) {
        if (newToken) {
            accessToken.value = newToken;

            sessionStorage.setItem('authToken', newToken);

            let dateExpires = expirationTime;
            dateExpires = new Date(dateExpires).toUTCString();

            // Set a cookie (WSS will use it)
            document.cookie = 'X-Authorization=' + newToken + '; expires=' + dateExpires + ';path=/';

            try {
                let host = location.hostname.split(':')[0].split('.');
                let domain = [host.pop(), host.pop()];

                let sessionDomain = domain.reverse().join('.');

                document.cookie = `session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${sessionDomain}; path=/`;
            } catch {
                //ignore
            }
        } else {
            // Wipe local storage and auth cookie

            sessionStorage.removeItem('authToken');
            sessionStorage.removeItem('gsAuth');

            document.cookie = `X-Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
            me.value = null;
        }
    }

    function register(newUser) {
        return new Promise((fulfill, reject) => {
            processing.value = true;

            // https://firebase.google.com/docs/auth/web/manage-users

            let user = null;

            let firebaseUser = null;
            // Register the new user
            // TODO: Ensure userId does not contain unknown characters as we use it in CSS Styles, etc. perhaps generate out own!
            let method;

            let credentials = newUser.credentials;

            let actionCodeSettings;

            if (newUser.invitation) {
                let continueUrl = `${window.location.protocol}//${window.location.host}/#/login?email=${newUser.email}&mode=invitation&code=${newUser.invitation.code}`;

                actionCodeSettings = {
                    url: continueUrl,
                };
            } else {
                let continueUrl = `${window.location.protocol}//${window.location.host}/#/login?email=${newUser.email}`;

                if (credentials.provider === 'link') {
                    continueUrl = `${window.location.protocol}//${window.location.host}/#/register?email=${newUser.email}&name=${newUser.fullName}&provider=${credentials.provider}&mode=signUp`;
                }

                actionCodeSettings = {
                    url: continueUrl,
                };
            }

            if (credentials.saml) {
                //method = signInWithCredential(auth, credentials.phone);
                logger.debug('authenticating using saml');
                let provider = new SAMLAuthProvider(credentials.saml.provider);
                method = signInWithPopup(auth, provider);
            } else if (credentials.provider === 'link') {
                if (isSignInWithEmailLink(auth, window.location.href)) {
                    method = signInWithEmailLink(auth, credentials.email, window.location.href);
                } else {
                    actionCodeSettings.handleCodeInApp = true;
                    method = sendSignInLinkToEmail(auth, credentials.email, actionCodeSettings);
                }
                //method = signInAnonymously(auth);
            } else {
                logger.debug('creating using a username/password');
                method = createUserWithEmailAndPassword(auth, credentials.email, credentials.password);
            }

            method
                .then((authData) => {
                    if (!authData) {
                        return fulfill();
                    }

                    logger.debug('register(newUser)', authData.user);

                    firebaseUser = authData.user;

                    setToken(authData.user.accessToken, authData.user.stsTokenManager.expirationTime);

                    let data;

                    if (credentials?.saml) {
                        data = {
                            auth: {
                                provider: credentials.saml.provider,
                                method: 'saml',
                                userId: authData.user.uid,
                            },
                            name: {
                                full: newUser.fullName,
                            },
                            email: newUser.email,
                            phone: newUser.phone,
                        };
                    } else {
                        data = {
                            auth: {
                                provider: 'firebase',
                                method: credentials.provider,
                                userId: authData.user.uid,
                            },
                            name: {
                                full: newUser.fullName,
                            },
                            email: newUser.email,
                            phone: newUser.phone,
                        };
                    }

                    if (newUser.referralCode) {
                        data.referralCode = newUser.referralCode;
                    }

                    return api.createUser(data);
                })
                .then((response) => {
                    user = response.data;
                    logger.debug('returned user info => ', user);
                })
                .then(() => {
                    if (credentials.provider === 'link') {
                        return Promise.resolve();
                    } else {
                        // We need to logout to force a refresh of the new updated token (adjusted on server)
                        return signOut(auth);
                    }
                })
                .then(() => {
                    if (credentials.saml) {
                        // Add the SAML provider to the user
                        user.saml = {
                            provider: credentials.saml.provider,
                        };

                        return Promise.resolve();
                    }

                    if (credentials.provider === 'link') {
                        //return Promise.resolve();
                        processing.value = false;
                        return getIdToken(firebaseUser, true);
                    } else {
                        return sendEmailVerification(firebaseUser, actionCodeSettings);
                    }
                })
                .then(() => {
                    // Return the newly create user (just an ID)
                    fulfill(user);
                })
                .catch((err) => {
                    //if (!credentials.saml) {
                    if (firebaseUser) {
                        deleteUser(firebaseUser).catch();
                    }
                    //}
                    reject(err);
                })
                .finally(() => (processing.value = false));
        });
    }

    function getLoginMethod(emailAddress) {
        return api.getLoginMethod(emailAddress);
    }

    async function signInWithInvitationCode(invitationCode, email, passcode) {
        sessionStorage.removeItem('gsAuth');

        let response = await api.signInWithInvitationCode(invitationCode, email, passcode);

        let authData = response.data;

        if (authData === 'auth/passcode-sent') {
            throw { code: 'auth/passcode-sent' };
        }

        let user = {
            accessToken: authData.accessToken,
            stsTokenManager: {
                issuedBy: authData.issuedBy,
                expirationTime: authData.expirationTime,
                refreshToken: authData.refreshToken,
            },
        };

        sessionStorage.setItem('gsAuth', JSON.stringify(user));

        currentAuthData.value = user;

        return {
            user: user,
        };
    }

    function login(credentials) {
        return new Promise((fulfill, reject) => {
            setToken(null);

            processing.value = true;

            let method;

            if (credentials.mode === 'view') {
                method = signInWithInvitationCode(credentials.code, credentials.email, credentials.passcode);
            } else {
                if (credentials.token) {
                    logger.debug('authenticating using a token');
                    method = signInWithCustomToken(auth, credentials.token);
                } else if (credentials.authData) {
                    //method = signInWithCredential(auth, credentials.phone);
                    logger.debug('authenticating using provided credentials');
                    method = Promise.resolve(credentials.authData);
                    //TODO: Future SAML
                    /*
                    } else if (credentials.saml) {
                        //method = signInWithCredential(auth, credentials.phone);
                        logger.debug('authenticating using saml')
                        let provider = new SAMLAuthProvider(credentials.saml.provider);
                        method = signInWithPopup(auth, provider);
                    */
                } else if (credentials.provider === 'link') {
                    if (isSignInWithEmailLink(auth, window.location.href)) {
                        method = signInWithEmailLink(auth, credentials.email, window.location.href);
                    } else {
                        let continueUrl = `${window.location.protocol}//${window.location.host}/#/login?email=${credentials.email}&provider=link&mode=signIn`;

                        let actionCodeSettings = {
                            url: continueUrl,
                            handleCodeInApp: true,
                        };

                        method = sendSignInLinkToEmail(auth, credentials.email, actionCodeSettings);
                    }
                    //method = signInAnonymously(auth);
                } else {
                    logger.debug('authenticating using a username/password');
                    method = signInWithEmailAndPassword(auth, credentials.email, credentials.password);
                }
            }

            method
                .then(async (authData) => {
                    //this.user.data = authData.user;

                    if (!authData) {
                        if (credentials.provider === 'link') return reject({ code: 'auth/link-sent' });
                        else return reject({ code: 'auth/invalid-auth' });
                    }

                    try {
                        logger.debug('logged in user details => ', authData);

                        if (authData.user.stsTokenManager.issuedBy === 'goskript') {
                            // ignore
                        } else {
                            if (!credentials.saml) {
                                try {
                                    multiFactorUser.value = multiFactor(authData.user);
                                } catch {
                                    // Ignore
                                }

                                if (!authData.user.emailVerified) {
                                    if (credentials.actionCode) {
                                        // Apply the action code so it sets the account as verified
                                        await applyCode(credentials.actionCode);
                                    } else {
                                        if (authData.user.email) {
                                            tempAuthData = authData.user;
                                            return reject({
                                                code: 'auth/email-not-verified',
                                            });
                                        }
                                    }
                                }
                            }
                        }

                        setToken(authData.user.accessToken, authData.user.stsTokenManager.expirationTime);

                        //TODO: Future SAML
                        /*
                        let response = await api.getLoginMigrate()

                        if (response?.data) {
                            let auth = response.data.auth;

                            if (auth?.migrate) {

                                if ( authData.user.providerData.findIndex( e => e.providerId ) === -1 ) {
                                    await unlink(authData.user, auth.migrate.provider);

                                    let provider = new SAMLAuthProvider(auth.migrate.provider);

                                    authData = await linkWithPopup(authData.user, provider);

                                    setToken(authData.user.accessToken, authData.user.stsTokenManager.expirationTime);
                                }


                            }
                        }
                        */
                        if (credentials.mode !== 'view') {
                            await updateCurrentUser();

                            let _me = toValue(me);

                            if (import.meta.env.VITE_ADMIN_ENABLE === 'true') {
                                if (_me.roles && !credentials.reload) {
                                    let response = await api.setRoles(_me.roles);

                                    if (response.status === 200) {
                                        // Prevent infinite recursion
                                        credentials.reload = true;
                                        await login(credentials);
                                    }
                                }
                            }

                            fulfill(_me);
                        } else {
                            fulfill(null);
                        }
                    } catch (err) {
                        if (err.code === 'auth/passcode-sent') {
                            return reject(err);
                        }

                        if (err.code === 'auth/credential-already-in-use') {
                            // ignore
                        }

                        signOut(auth).catch();
                        reject(err);
                    }
                })
                .catch((err) => {
                    //notFound.value = (err.code === 'auth/user-not-found');
                    //invalidAuth.value = (err.code === 'auth/wrong-password');
                    reject(err);
                })
                .finally(() => (processing.value = false));
        });
    }

    function remove() {
        return new Promise((fulfill, reject) => {
            const user = auth.currentUser;

            if (!user) return fulfill();

            deleteUser(user)
                .then(() => {})
                .catch((err) => {
                    reject(err);
                });
        });
    }

    function logout(redirect = true) {
        return new Promise((fulfill /*reject*/) => {
            //this.user.data = null;
            api.stopPolling();

            if (toValue(decodedToken)?.iss === 'goskript') {
                setToken(null);

                fulfill();
            } else {
                signOut(auth)
                    .then(() => {
                        if (redirect) {
                            if (process.env.NODE_ENV === 'development') {
                                window.location = '/#/login';
                            } else {
                                window.location = '/';
                            }
                        }

                        fulfill();
                    })
                    .catch((err) => {
                        logger.error(err);
                    });
            }
        });
    }

    async function refresh() {
        // Refresh the token if its about to expire.
        if (toValue(currentAuthData)) {
            if (toValue(decodedToken)?.iss === 'goskript') {
                try {
                    let expDelta = Math.floor((toValue(currentAuthData).stsTokenManager.expirationTime - Date.now()) / 1000);

                    // 5 Mins before a token expiration, refresh it
                    if (expDelta > 5 * 60) return;

                    let refreshToken = toValue(currentAuthData).stsTokenManager.refreshToken;

                    let response = await api.requestNewToken(refreshToken);

                    let authData = response.data;

                    let user = {
                        accessToken: authData.accessToken,
                        stsTokenManager: {
                            issuedBy: authData.issuedBy,
                            expirationTime: authData.expirationTime,
                            refreshToken: authData.refreshToken,
                        },
                    };

                    currentAuthData.value = user;

                    sessionStorage.setItem('gsAuth', JSON.stringify(user));

                    setToken(authData['accessToken'], authData['expirationTime']);
                } catch (err) {
                    logger.error(err);
                }

                return;
            }
            //let expDelta = (currentAuthData.stsTokenManager.expirationTime - Date.now());

            // 5 Mins before a token expiration, refresh it
            //if (expDelta > (5 * 60 * 1000))
            //    return;

            //logger.debug(`Token is going to expire, forcing a refresh`);

            if (auth.currentUser) {
                auth.currentUser
                    .getIdToken()
                    .then(() => {
                        logger.debug('auth.currentUser.getIdToken() succeeded');
                    })
                    .catch((err) => {
                        logger.error('auth.currentUser.getIdToken() failed', err);
                    });
            }
        }
    }

    function checkCode(actionCode) {
        return checkActionCode(auth, actionCode);
    }

    function applyCode(actionCode) {
        return applyActionCode(auth, actionCode);
    }

    function sendVerificationLink() {
        return sendEmailVerification(tempAuthData ?? toValue(currentAuthData));
    }

    function sendPasswordReset(email) {
        return sendPasswordResetEmail(auth, email);
    }

    function checkResetCode(actionCode) {
        // Verify the password reset code is valid.
        return verifyPasswordResetCode(auth, actionCode);
    }

    function resetPassword(newPassword, actionCode) {
        return confirmPasswordReset(auth, actionCode, newPassword);
    }

    async function getMultiFactorSession() {
        return await multiFactor(toValue(currentAuthData)).getSession();
    }

    async function registerMFADevice(multiFactorAssertion, mfaDisplayName = null) {
        const _multiFactorUser = toValue(multiFactorUser);
        await _multiFactorUser.enroll(multiFactorAssertion, mfaDisplayName);

        multiFactorUser.value = _multiFactorUser;

        return toValue(currentAuthData);
    }

    function getMFAResolver(err, preferSMS = false) {
        let resolver = getMultiFactorResolver(auth, err);

        // Sort the hints so the Applications are first selected as we get charges for SMS.
        resolver.hints.sort((a) =>
            a.factorId === (preferSMS ? PhoneMultiFactorGenerator.FACTOR_ID : TotpMultiFactorGenerator.FACTOR_ID) ? -1 : 1,
        );

        return resolver;
    }

    async function removeMFADevice(enrolledFactor) {
        const _multiFactorUser = toValue(multiFactorUser);
        await _multiFactorUser.unenroll(enrolledFactor);

        multiFactorUser.value = _multiFactorUser;

        //_multiFactorUser.enrolledFactors = _multiFactorUser.enrolledFactors.filter( e => e.uid !== enrolledFactor.uid );
    }

    function changePassword(authData, newPassword) {
        return new Promise((fulfill, reject) => {
            processing.value = true;

            updatePassword(authData.user, newPassword)
                .then(() => {
                    fulfill();
                })
                .catch((err) => {
                    reject(err);
                })
                .finally(() => (processing.value = false));
        });
    }

    function updateProfile(data) {
        return new Promise((fulfill, reject) => {
            processing.value = true;

            api.updateProfile(data)
                .then(async (response) => {
                    await updateCurrentUser(true);

                    fulfill(response);
                })
                .catch((err) => {
                    logger.error(err);
                    reject(err);
                })
                .finally(() => (processing.value = false));
        });
    }

    function lookupUser(id, caseId = null, orgId = null) {
        return new Promise((fulfill) => {
            api.findUser(id, caseId, orgId)
                .then((response) => {
                    fulfill(response.data);
                })
                .catch(() => {
                    return null;
                });
        });
    }

    function lookupInvitation(id, caseId) {
        return new Promise((fulfill, reject) => {
            api.findInvitation(id, caseId)
                .then((response) => {
                    fulfill(response.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    function getUsers() {
        return new Promise((fulfill) => {
            api.getUsers()
                .then((response) => {
                    fulfill(response.data);
                })
                .catch(() => {
                    return null;
                });
        });
    }

    return {
        me,
        userId,
        mfaDevices,
        loggedIn,

        decodedToken,
        isReadOnly,
        isViewMode,

        register,
        processing,
        getLoginMethod,
        login,
        remove,
        logout,
        refresh,
        sendPasswordReset,
        sendVerificationLink,
        checkCode,
        checkResetCode,
        resetPassword,
        changePassword,
        applyCode,
        updateProfile,
        lookupUser,
        lookupInvitation,
        getUsers,

        getMultiFactorSession,
        registerMFADevice,
        getMFAResolver,
        removeMFADevice,
    };
});
